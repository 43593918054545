<template>
  <b-navbar id="nav-top" class="nav" >
    <template #brand>
      <b-navbar-item :to="redirectPathByRole" id="company-logo" tag="router-link">
        <img class="logo" :src="logo" alt="Company Logo" :key="navtopKey"/>
      </b-navbar-item>
    </template>

    <template #start>
      <NavbarItem v-if="isUserWholesaler || isUserAdmin || isUserDsr" toPath="/turn" label="">
        <b-image class="nav-top__turn-logo" :src="require('@/assets/svg/carmigo-turn-logo.svg')" />
      </NavbarItem>

      <NavbarItem
        v-if="!isAuthenticated"
        label="Sell"
        toPath="/home"
      />

      <NavbarItem label="Buy" toPath="/live-auctions" v-if="isAuthenticated && (isUserAdmin || isUserDsr || isUserBuyer || isUserStoreManager || isUserCarmigoDirectDealer())" />

      <!-- Buyer Dashboard -->
      <NavbarItem 
        v-if="isUserBuyer"
        v-bind="{
          label: 'Dashboard',
          toPath: '/dashboard/buying/0',
          showAlertIcon: Boolean(buyerActionRequiredCount),
          alertIconAttrs: {
            alertType: 'actionRequired',
          }
        }"
      />

      <NavbarItem v-if="isUserInspector || isUserAdmin || isUserDsr"
        label="Inspection"
        toPath="/inspector"
      />

      <NavbarItem v-if="isUserAdmin || isUserDsr"
        label="Admin"
        toPath="/admin"
      />
    </template>

    <template v-if="isAuthenticated && (bulmaBreakpoint.mobile || bulmaBreakpoint.tablet)" #burger="{ toggleActive, isOpened }">
      <div class="flex-row align-center">
        <AppNavbarNotifications
          :displayNotificationFeed="displayNotificationFeed"
          @update:displayNotificationFeed="$emit('update:displayNotificationFeed', $event)"
        />

        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
        <a @click.prevent="toggleActive" @keyup.enter.prevent="toggleActive" class="user-menu-mobile">
          <TheUserMenuIcon :icon="isOpened ? 'close' : 'menu'" :text="userFirstLetter" />
        </a>
      </div>
    </template>

    <template #end>
      <TheBuyerRebateProgressBar 
        v-if="isUserBuyer"
        :class="{ 
          'mr-2': bulmaBreakpoint.desktop, 
          'mt-1': bulmaBreakpoint.mobile || bulmaBreakpoint.tablet, 
          'justify-center': bulmaBreakpoint.mobile || bulmaBreakpoint.tablet,
        }" 
        v-bind="{
          displayWindowStepCount: bulmaBreakpoint.mobile ? 4 : 6,
          refreshKey: buyerRebateKey,
        }"
      />

      <!-- Notifications -->
      <AppNavbarNotifications 
        v-if="isAuthenticated && !(bulmaBreakpoint.mobile || bulmaBreakpoint.tablet)"
        :displayNotificationFeed="displayNotificationFeed"
        @update:displayNotificationFeed="$emit('update:displayNotificationFeed', $event)"
      />

      <b-navbar-item tag="div" v-if="showACH">
        <div class="buttons">
          <SetUpACHButton />
        </div>
      </b-navbar-item>

      <!-- DropDown -->
      <b-navbar-dropdown @click="$emit('update:displayNotificationFeed', false)" v-if="isAuthenticated" right arrowless class="user-menu-dropdown">
        <template #label>
          <TheUserMenuIcon class="is-hidden-touch" :text="userFirstLetter" />
        </template>

        <!-- Zip -->
        <AppNavbarZip v-if="isUserBuyer || isUserAdmin || isUserDsr" />

        <NavbarItem label="Sell" v-if="showHome" />

        <!-- Seller Dashboard -->
        <NavbarItem v-if='isUserSeller && !isUserBuyer'
          label="My Cars"
          toPath='/dashboard/selling/0'
        />

        <NavbarItem v-if="isUserCarmigoDirectDealer()"
          label="Carmigo Direct"
          toPath="/carmigoDirect"
          showAlertIcon
          v-bind="{
            alertIconAttrs: { fill: numCarmigoDirectAlerts },
          }"
        />

        <NavbarItem v-if="isUserStoreManager"
          label="Stores"
          toPath="/stores"
        />
        <NavbarItem v-if="showReferrals"
          label="Referrals"
          toPath="/referrals"
        />
        <NavbarItem v-if="isUserAdmin || isUserBuyer"
          label="Arbitrations"
          toPath="/arbitrations"
          showAlertIcon
          v-bind="{
            alertIconAttrs: { fill: numArbitrationMessages },
          }"
        />
        <NavbarItem
          label="Logout"
          icon="logout"
          toPath="/logout"
        />
      </b-navbar-dropdown>

      <!-- Login -->
      <b-navbar-item
        v-else
        class="nav-item"
        tag="router-link"
        :to="{ path: '/login' }"
      >
        Login
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import Vue, { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { mapGetters } from 'vuex';
import store from '@/vuex';
import { Navbar } from 'buefy';
import AlertIcon from '../AlertIcon';
import AppNavbarNotifications from '../AppNavbarNotifications.vue';
import NavbarItem from './NavbarItem.vue';
import TheBuyerRebateProgressBar from '../TheBuyerRebateProgressBar.vue';
import TheUserMenuIcon from './TheUserMenuIcon.vue';
import AppNavbarZip from '../AppNavbarZip.vue';
import {
  GET,
  tokenExpires,
  getBaseURL,
  needsSellerDwollaSetup,
  checkHasDwollaFundingSource,
  needsSellerPaymentInfo,
} from '../../api';
import SelectPayment from '../SelectPayment.vue';
import {
  getRedirectPathByRole,
  isCarmigoDomain,
  isUserCarmigoDirectDealer,
  mapUserRoleGetters,
} from '../../utils';
import SetUpACHButton from '../Buttons/SetUpACHButton.vue';
import './assets/icons/style.css';
import breakpointMixin from '@/mixins/breakpoint';

Vue.use(Navbar);

export default defineComponent({
  name: 'NavBar',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    navtopLogo: {
      type: String,
      required: false,
    },
    displayNotificationFeed: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [breakpointMixin],
  setup() {
    const buyerActionRequiredCount = computed(() => store.getters.getBuyerActionRequiredCount);

    // Refresh Buyer Rebate on route change to get other buyers' purchases
    const route = useRoute();
    const buyerRebateKey = ref(0);
    watch(route, (to) => buyerRebateKey.value++);

    return {
      buyerActionRequiredCount,
      buyerRebateKey,
    }
  },
  data() {
    return {
      negotiating: [],
      numAuctions: 0,
      numArbitrationMessages: 0,
      numCarmigoDirectAlerts: 0,
      numCheckouts: 0,
      numNegotiations: 0,
      userId: '', // This is used to know if we need to update the number of checkouts
      paymentOpen: false, // component was opening twice
      logo: '',
      navtopKey: 0,
    };
  },
  components: {
    AlertIcon,
    AppNavbarNotifications,
    NavbarItem,
    SetUpACHButton,
    AppNavbarZip,
    TheBuyerRebateProgressBar,
    TheUserMenuIcon,
  },
  computed: {
    redirectPathByRole() {
      return getRedirectPathByRole().path;
    },
    numActions() {
      return this.numCheckouts + this.numNegotiations;
    },
    userName() {
      return `${this.$store.state.user.profile?.firstName} ${this.$store.state.user.profile?.lastName}`;
    },
    userFirstLetter() {
      return this.$store.state.user.profile?.firstName?.charAt(0) ?? '';
    },
    ...mapUserRoleGetters(),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    showAuctions() {
      return this.isAuthenticated;
    },
    showHome() {
      if (this.isUserSeller && !this.$store.state.isCarmigoDirect) {
        return true;
      }

      if (this.isUserWholesaler) {
        return true;
      }
      
      const notAllowedRoles = this.isUserBuyer || this.isUserStoreManager || this.isUserCarmigoDirect;
      if (notAllowedRoles || !isCarmigoDomain()) {
        return false;
      }
      return true;
    },
    showReferrals() {
      return this.isAuthenticated && !this.isUserCarmigoDirect && isCarmigoDomain();
    },
    showACH() {
        return isCarmigoDomain() && this.isAuthenticated && this.isUserSeller && this.$store.state.needACH;
    },
  },

  methods: {
    isUserCarmigoDirectDealer,
    getNavtopLogo() {
      const { branding } = this.$store.state;
      if (branding && branding.logoUrlWide) {
        this.logo = branding.logoUrlWide; // add logo

        // remove href
        const navbarItem = document.getElementById('company-logo');
        navbarItem.href = '';
      } else {
        const img = require.context('../../assets/', false, /\.png$/);
        this.logo = img('./logo_small.png');
      }
    },
    makeNavbarSticky() {
      const navbar = document.getElementById('nav-top');
      const sticky = navbar.offsetTop;

      window.pageYOffset >= sticky
        ? navbar.classList.add('sticky')
        : navbar.classList.remove('sticky');
    },
    async updated() {
      this.getCarmigoDirectAlerts();

      let newId;

      if (this.isAuthenticated) {
        newId = this.$store.state.user.profile.id;
      }

      if (this.userId != newId) {
        this.userId = newId;
        this.numCheckouts = 0;
        this.numNegotiations = 0;
        this.numArbitrationMessages = 0;
      }

      // console.log(this.$store.state.user?.profile)

      // Only run through ACH setup checks if user is on a carmigo domain, not carmigo direct
      if (isCarmigoDomain() && this.isAuthenticated && this.isUserSeller) {
        this.$store.state.needACH = await needsSellerPaymentInfo(this.$store.state.user.profile.id);
      }
    },

    async getCarmigoDirectAlerts() {
      if (!this.isUserCarmigoDirectDealer()) {
        return;
      }
      if (!this.isAuthenticated) {
        return;
      }
      GET(`/carmigoDirect/alerts/${this.$store.state.user.profile.id}`).then((res) => {
        this.numCarmigoDirectAlerts = parseInt(res.data);
      });
    },

    async getUnreadArbitrationMessages() {
      if (!this.isAuthenticated) {
        return;
      }

      GET(
        `arbitration/numberOfUnreadArbitrationMessages/${this.$store.state.user?.profile.id}`,
      ).then((response) => {
        if (response) this.numArbitrationMessages = response.data;
      });
    },

    openPaymentModal() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          CarmigoUserID: this.$store.state.user.profile.id,
          DestinationRepository: 'profile',
          dwollaCustomerURL: this.$store.state.user?.profile.dwollaCustomerURL,
        },
        component: SelectPayment,
        hasModalCard: true,
        trapFocus: false,
        canCancel: ['p'],
      });
    },
  },
  updated() {
    this.updated();
    this.getNavtopLogo();
  },
  async mounted() {
    this.getNavtopLogo();

    window.addEventListener('scroll', this.makeNavbarSticky());

    this.updated();

    // prevent Carmigo logo if applying CarmigoDirect branding
    this.$root.$on('applyingBranding', () => {
      console.log('applying branding');
      this.logo = '';
      this.navtopKey++;
    });

    this.$root.$on('updateCarmigoDirect', () => this.getCarmigoDirectAlerts());

    this.$root.$on('updateUnreadArbitrationMessages', () => {
      if (this.isUserSeller) {
        return;
      }
      this.getUnreadArbitrationMessages();
    });
  },
});
</script>

<style scoped lang="scss">
.nav {
  box-shadow: 0px 3px 6px #00000029;

  & .logo {
    width: 140px;
    object-fit: cover;
  }

  & .logged-user {
    display: flex;
    font-size: 0.735rem;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: var(--primary-dark);
  }
}
.nav-item {
  & span {
    font-weight: 500 !important;
  }

  & span.icon {
    color: inherit;
    // margin-top: -10px;
    // margin-left: 0;
    // margin-right: 7px;
  }

  &:hover {
    color: var(--primary-hover);
  }

  &:focus {
    color: var(--primary-dark);
  }
}

.router-link-exact-active {
  cursor: pointer;
  color: var(--primary);
  background-color: transparent;
}

.display-flex {
  display: flex;
}

.customNavDropDown {
  position: absolute;
  height: 100%;
}

.nav-top__turn-logo {
  width: 52px;
}

.user-menu-mobile, .user-menu-dropdown :deep(a) {
  color: inherit !important;

  &:hover {
    color: inherit !important;
  }

  &:active {
    color: inherit !important;
  }
}

.user-menu-dropdown :deep(.navbar-link:first-child) {
  @include bulmaMixins.mobile {
    display: none;
  }
}

:deep(.navbar-brand) {
  justify-content: space-between;
}

:deep(.navbar-brand .user-menu-mobile) {
  @include bulmaMixins.mobile {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0;
    margin-right: 12px;
  }
  @include bulmaMixins.tablet {
    padding-right: 12px;
  }
}
</style>
