import { GET, getMarketplaceListingDetails, getMarketplaceListingHighestOffer, getSecondChanceListingForBuyerDashboard, getSendToSecondChancePricing, PUT, sendToSecondChance, updateMarketplaceListing } from '@/api';
import {
    BuyerMarketplaceOffer,
    DealerMarketplaceListing,
    marketplaceCurrentOfferDTO,
    MarketplaceListingOfferDataDTOFormatted,
    MarketplaceRefSnapshot,
    VehicleStatus,
} from '@/types';
import { BDialogConfig } from 'buefy/types/components';
import { database } from '@/firebase';
import { openConfirmationDialog, openErrorDialog, toCurrency, isVehicleSold, isVehicleAuctioned, isVehicleInStatus, isVehicleStatusCheckoutOrLater, openToast, openModal } from '@/utils';
import { isEqual } from 'lodash';
import { isEqual as isDateEqual, parseISO } from 'date-fns';
import {
    onUnmounted,
    Ref,
    ref,
    SetupContext,
    watch,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import { useRdbListener } from './firebase';
import { RouteQueryToDatabaseFieldMap, SortDirection, usePaginatedFetch } from './turn';
import { VehicleStatusesEnum } from '@/enums';
import { ListingType } from './srp';
import { useVehicleDetailsCards } from './admin';

import TheEditListingPricingModal from '../components/TheEditListingPricingModal.vue';
import TheSendToSecondChanceModal from '../components/TheSendToSecondChanceModal.vue';

interface DealerMarketplaceListingWithListingOffers extends DealerMarketplaceListing {
    listingOffers: MarketplaceListingOfferDataDTOFormatted[]
    // Listing offers table rerender key
    listingOffersKey: number
}

// TODO: We don't need this composable anymore. Remove this when we start deleting the old tables.
export function useGetDealerMarketplaceTableData(storeId: number): {
    loadingTableData: Ref<boolean>
    tableData: Ref<DealerMarketplaceListingWithListingOffers[]>
    updateTableRow(marketplaceListingId: number, newValue: Partial<DealerMarketplaceListingWithListingOffers>): void
    removeTableRow(marketplaceListingId: number): void,
    getDealerMarketplaceListings: () => Promise<void>
} {
    const loadingTableData = ref(false);
    // Listing offers array is here so we can easily mutate when there's an offer update
    const tableData = ref<DealerMarketplaceListingWithListingOffers[]>([]);

    getDealerMarketplaceListings();

    async function getDealerMarketplaceListings(field?: string, value?: string) {
        if (!storeId) {
            return;
        }
        loadingTableData.value = true;
        tableData.value = await PUT<DealerMarketplaceListingWithListingOffers[]>(`/marketplace/getMarketplaceListing/dealerMarketplace`, {
            storeId,
        }).then((res) => {
                loadingTableData.value = false;
                return res.data.map((item) => ({
                    ...item,
                    listingOffers: [],
                    listingOffersKey: 0,
                }));
            }).catch((error) => {
                openErrorDialog({
                    title: 'Could not fetch marketplace listings',
                    message: `We encountered an error while fetching the marketplace listings for store ${storeId}`,
                    error,
                });
                return [];
            });
    }

    function updateTableRow(marketplaceListingId: number, newValue: DealerMarketplaceListingWithListingOffers) {
        const listingIndex = tableData.value.findIndex((item) => item.marketplaceListingId === marketplaceListingId);

        Object.assign(tableData.value[listingIndex], newValue);

        if (!isEqual(tableData.value[listingIndex].listingOffers, newValue.listingOffers)) {
            tableData.value[listingIndex].listingOffersKey += 1;
        }
    }

    function removeTableRow(marketplaceListingId: number) {
        const listingIndex = tableData.value.findIndex((item) => item.marketplaceListingId === marketplaceListingId);
        tableData.value.splice(listingIndex, 1);
    }

    return {
        loadingTableData,
        tableData,
        updateTableRow,
        removeTableRow,
        getDealerMarketplaceListings,
    };
}

export function useDealerMarketplaceTableData({
    emit,
    defaultSortField,
    defaultSortDirection,
    isSecondChance,
}: {
    emit: (event: 'updateTotalCount', ...args: any[]) => void,
    defaultSortField: string
    defaultSortDirection: SortDirection,
    isSecondChance?: boolean,
}) {
    const tableData = ref<DealerMarketplaceListingWithListingOffers[]>([]);
    const route = useRoute();

    const routeQueryToDatabaseFieldMap: RouteQueryToDatabaseFieldMap = {
        storeId: 'vehicle_listing.seller_store_id',
        vin: 'vehicle.vin',
    };

    const fetchResult = usePaginatedFetch<{
      vehicles: DealerMarketplaceListingWithListingOffers[];
      totalCount: number;
    }>('/marketplace/getMarketplaceListing/dealerMarketplace', {
        initialData: {
            vehicles: [],
            totalCount: 0,
        },
        onSuccess({ totalCount, vehicles }) {
            emit('updateTotalCount', totalCount);
            tableData.value = vehicles.map((item) => ({
                ...item,
                listingOffers: [],
                listingOffersKey: 0,
            }));
        },
        onError() {
            openErrorDialog({
                title: 'Could not fetch marketplace listings',
                message: `We encountered an error while fetching the marketplace listings for store ${route.query.storeId}`,
            });
        },
        defaultSortField,
        defaultSortOrder: defaultSortDirection,
        routeQueryToDatabaseFieldMap,
        additionalFilters: [
            {
                property: 'vehicleListing.vehicleStatusId',
                comparator: '=',
                values: isSecondChance ? VehicleStatusesEnum.SecondChance : VehicleStatusesEnum.InMarketplace,
            }
        ]
    });

    function updateTableRow(marketplaceListingId: number, newValue: Partial<DealerMarketplaceListingWithListingOffers>) {
        const listingIndex = tableData.value.findIndex((item) => item.marketplaceListingId === marketplaceListingId);

        Object.assign(tableData.value[listingIndex], newValue);

        if (!isEqual(tableData.value[listingIndex].listingOffers, newValue.listingOffers)) {
            tableData.value[listingIndex].listingOffersKey += 1;
        }
    }

    function removeTableRow(marketplaceListingId: number) {
        const listingIndex = tableData.value.findIndex((item) => item.marketplaceListingId === marketplaceListingId);
        tableData.value.splice(listingIndex, 1);
    }

    return {
        ...fetchResult,
        data: tableData,
        updateTableRow,
        removeTableRow,
    };
}

type MarketplaceListingId = number
type MarketplaceOffer = Record<MarketplaceListingId, { updatedDate: string }>
export type MarketplaceRefRecord = {
    status: VehicleStatus
    viewCount: number
    offerCount: number
    offers: MarketplaceOffer
}

type MarketplaceSnapshot = {
    isInitialSnapshot?: boolean,
    oldSnapshotValue?: MarketplaceRefRecord | null,
    newSnapshotValue: MarketplaceRefRecord | null,
}

function getUpdatedMarketplaceOfferIds({ oldOffers, newOffers }: { oldOffers: MarketplaceOffer, newOffers: MarketplaceOffer }) {
    const updatedMarketplaceOfferIds = Object.keys(newOffers).filter(marketplaceOfferId => {
        const oldUpdatedDate = oldOffers[Number(marketplaceOfferId)]?.updatedDate;
        const newUpdatedDate = newOffers[Number(marketplaceOfferId)]?.updatedDate;
        return !isDateEqual(parseISO(oldUpdatedDate), parseISO(newUpdatedDate));
    }).map(Number);
    return updatedMarketplaceOfferIds;
}

function getSnapshotOffers({ oldSnapshotValue, newSnapshotValue }: MarketplaceSnapshot) {
    let oldOffers = oldSnapshotValue?.offers ?? {};
    let newOffers = newSnapshotValue?.offers ?? {};
    return {
        oldOffers,
        newOffers,
        offersUpdated: !isEqual(oldOffers, newOffers)
    }
}

function checkIsOfferUpdated({ marketplaceOfferId, oldSnapshotValue, newSnapshotValue }: { marketplaceOfferId: number } & MarketplaceSnapshot) {
    let oldOffers = oldSnapshotValue?.offers ?? {};
    let newOffers = newSnapshotValue?.offers ?? {};

    const oldUpdatedDate = oldOffers[Number(marketplaceOfferId)]?.updatedDate;
    const newUpdatedDate = newOffers[Number(marketplaceOfferId)]?.updatedDate;
    if (!oldUpdatedDate && !newUpdatedDate) {
        return false;
    }
    return !isDateEqual(parseISO(oldUpdatedDate), parseISO(newUpdatedDate));
}

export function useBuyerMarketplaceOfferListener({ vehicleListingId, marketplaceOfferId, onOfferUpdate}: {
    vehicleListingId: number,
    marketplaceOfferId: number,
    onOfferUpdate: () => void
}) {
    function getUpdatedOfferAndUpdateTable({ isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: MarketplaceSnapshot) {
        let isOfferUpdated = checkIsOfferUpdated({ marketplaceOfferId, oldSnapshotValue, newSnapshotValue });
        if (!isInitialSnapshot && isOfferUpdated) {
            onOfferUpdate();
        }
    }

    useSingleMarketplaceListingListener(vehicleListingId, getUpdatedOfferAndUpdateTable);
}

export function useDealerMarketplaceOfferListener({ vehicleListingId, onOfferUpdate }: {
    vehicleListingId: number,
    onOfferUpdate: ({ updatedMarketplaceOfferIds }: { updatedMarketplaceOfferIds: number[] }) => void,
}) {
    function getUpdatedOffersAndUpdateTable({ isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: MarketplaceSnapshot) {
        let { oldOffers, newOffers, offersUpdated } = getSnapshotOffers({ oldSnapshotValue, newSnapshotValue });
        if (offersUpdated && !isInitialSnapshot) {
            let updatedMarketplaceOfferIds = getUpdatedMarketplaceOfferIds({ oldOffers, newOffers });
            onOfferUpdate({ updatedMarketplaceOfferIds });
        }
    }

    useSingleMarketplaceListingListener(vehicleListingId, getUpdatedOffersAndUpdateTable);
}

export function useDealerMarketplaceListener({
    tableData,
    updateTableRow,
    removeTableRow,
    onStatusUpdate,
}: {
    tableData: Ref<DealerMarketplaceListingWithListingOffers[]>,
    updateTableRow: (marketplaceListingId: number, newValue: Partial<DealerMarketplaceListingWithListingOffers>) => void
    removeTableRow: (marketplaceListingId: number) => void
    onStatusUpdate(listing: DealerMarketplaceListingWithListingOffers, status: 'Sold' | 'NotSold'): void
}) {
    async function getMarketplaceListingOfferByIds(marketplaceListingId: number, listingOfferIds: number[]) {
        try {
            const offers = await Promise.all(
                listingOfferIds.map((offerId) => GET<MarketplaceListingOfferDataDTOFormatted>(`/marketplace/getMarketplaceListing/marketplaceListingOffer/${offerId}`)
                    .then((res) => res.data)),
            );

            offers.forEach((offer) => {
                // Get position of marketplace listing by vehicle listing id
                const listingIndex = tableData.value.findIndex((item) => item.marketplaceListingId === marketplaceListingId);
                // Get position of listing offer by marketplace offer id
                const listingOfferIndex = tableData.value[listingIndex].listingOffers.findIndex((item) => item.marketplaceOfferId === offer.marketplaceOfferId);
                // If exists, update the offer
                if (listingOfferIndex !== -1) {
                    const updatedOffers = tableData.value[listingIndex].listingOffers;
                    updatedOffers[listingOfferIndex] = {
                        ...updatedOffers[listingOfferIndex],
                        ...offer,
                    };

                    updateTableRow(marketplaceListingId, {
                        ...tableData.value[listingIndex],
                        listingOffers: updatedOffers,
                    });
                } else {
                    // If not, add the offer
                    updateTableRow(marketplaceListingId, {
                        ...tableData.value[listingIndex],
                        listingOffers: [
                            ...tableData.value[listingIndex].listingOffers,
                            offer,
                        ],
                    });
                }
            });
        } catch (error) {
            openErrorDialog({
                title: 'Could not fetch marketplace listing offers',
                message: `We encountered an error while fetching the marketplace listings offers for listing with ID ${marketplaceListingId}`,
                error,
            });
        }
    }

    const { removeListingListener } = useMarketplaceListener(tableData, async ({
        listing,
        isInitialSnapshot,
        oldSnapshotValue,
        newSnapshotValue,
    }) => {
        const { oldOffers, newOffers, offersUpdated } = getSnapshotOffers({ oldSnapshotValue, newSnapshotValue });

        // Update highest offer and listing offers if not initial load and offers updated.
        if (offersUpdated && !isInitialSnapshot) {
            let updatedMarketplaceOfferIds = getUpdatedMarketplaceOfferIds({ oldOffers, newOffers });

            const [updatedListing, highestOffer] = await Promise.all([
                getMarketplaceListingDetails(listing.marketplaceListingId),
                getMarketplaceListingHighestOffer(listing.marketplaceListingId),
                getMarketplaceListingOfferByIds(listing.marketplaceListingId, updatedMarketplaceOfferIds),
            ]);

            updateTableRow(listing.marketplaceListingId, {
                numViews: updatedListing?.numViews ?? listing.numViews,
                numOffers: updatedListing?.numOffers ?? listing.numOffers,
                highestOfferAmount: highestOffer?.highestOfferAmount ?? listing.highestOfferAmount,
            });
        }

        const statusChanged = oldSnapshotValue?.status !== newSnapshotValue.status;

        if (statusChanged) {
            const isSoldOrAuctioningOrInactive = isVehicleSold(newSnapshotValue.status) || isVehicleAuctioned(newSnapshotValue.status) || isVehicleInStatus(['Inactive'], newSnapshotValue.status);

            if (isSoldOrAuctioningOrInactive) {
                removeTableRow(listing.marketplaceListingId);
                removeListingListener(listing.vehicleListingId);
            }

            if (isVehicleSold(newSnapshotValue.status)) {
                onStatusUpdate(listing, 'Sold');
            } else if (isVehicleInStatus(['Inactive'], newSnapshotValue.status)) {
                onStatusUpdate(listing, 'NotSold');
            }
        }
    });
}

export function useBuyerDashboardMarketplaceListener({
    tableData,
    updateTableRow,
    removeTableRow,
    onStatusUpdate,
    isSecondChance,
}: {
    tableData: Ref<BuyerMarketplaceOffer[]>
    updateTableRow(marketplaceListingId: number, newValue: Partial<DealerMarketplaceListingWithListingOffers>): void
    removeTableRow(marketplaceListingId: number): void
    onStatusUpdate(vehicleListingId: number): void
    isSecondChance?: boolean
}) {
    async function getMarketplaceListingForBuyerDashboard(marketplaceListingId: number) {
        try {
            const { data } = await GET<BuyerMarketplaceOffer>(`/marketplace/getMarketplaceListing/buyerDashboard/${marketplaceListingId}`);
            return data;
        } catch (error) {
            openErrorDialog({
                title: 'Could not fetch marketplace listing',
                message: `We encountered an error while fetching the marketplace listing for ID ${marketplaceListingId}`,
                error,
            });
            return null;
        }
    }

    const { removeListingListener } = useMarketplaceListener(tableData, async ({
        listing, isInitialSnapshot, oldSnapshotValue, newSnapshotValue,
    }) => {
        const { offersUpdated } = getSnapshotOffers({ oldSnapshotValue, newSnapshotValue });

        // Offers updated, and is not initial snapshot
        if (offersUpdated && !isInitialSnapshot) {
            const updatedListing = isSecondChance 
                ? await getSecondChanceListingForBuyerDashboard(listing.marketplaceListingId) 
                : await getMarketplaceListingForBuyerDashboard(listing.marketplaceListingId);
            if (updatedListing) {
                updateTableRow(listing.marketplaceListingId, updatedListing as Partial<DealerMarketplaceListingWithListingOffers>);
            }
        }

        // Previous status and new snapshot status are different
        const statusChanged = oldSnapshotValue?.status !== newSnapshotValue.status;

        if (statusChanged) {
            const isSoldOrAuctioningOrInactive = isVehicleSold(newSnapshotValue.status) || isVehicleAuctioned(newSnapshotValue.status) || isVehicleInStatus(['Inactive'], newSnapshotValue.status);
            if (isSoldOrAuctioningOrInactive) {
                removeTableRow(listing.marketplaceListingId);
                removeListingListener(listing.vehicleListingId);
            }
            onStatusUpdate(listing.vehicleListingId);
        }
    });
}

/**
 * Listen to a list of marketplace listings
 *
 */
export function useMarketplaceListener<T extends DealerMarketplaceListingWithListingOffers | BuyerMarketplaceOffer>(tableData: Ref<T[]>, onSnapshot: (payload: {
    listing: T
    isInitialSnapshot: boolean
    oldSnapshotValue?: MarketplaceRefRecord
    newSnapshotValue: MarketplaceRefRecord
}) => void) {
    type VehicleListingId = number
    const marketplaceDatabaseRefs = ref<Record<VehicleListingId, ReturnType<typeof database['ref']>>>({});
    const marketplaceSnapshots = ref<Record<VehicleListingId, MarketplaceRefRecord>>({});

    function onMarketplaceChange() {
        if (tableData.value.length === 0) {
            return;
        }

        tableData.value.forEach((listing) => {
            if (!marketplaceDatabaseRefs.value[listing.vehicleListingId]) {
                const marketplaceRef = database.ref(`marketplace/${listing.vehicleListingId}`);
                marketplaceDatabaseRefs.value[listing.vehicleListingId] = marketplaceRef;
                marketplaceRef.on('value', (snapshot) => {
                    if (!snapshot.exists()) {
                        return;
                    }

                    const oldValue = marketplaceSnapshots.value[listing.vehicleListingId];
                    const newValue = snapshot.val() as MarketplaceRefRecord;
                    onSnapshot({
                        listing,
                        isInitialSnapshot: oldValue === undefined,
                        oldSnapshotValue: oldValue,
                        newSnapshotValue: newValue,
                    });
                    marketplaceSnapshots.value[listing.vehicleListingId] = newValue;
                });
            }
        });
    }

    watch(tableData, onMarketplaceChange);

    function unsubscribeToMarketplaceRefs() {
        Object.values(marketplaceDatabaseRefs.value).forEach((reference) => {
            reference.off('value');
        });
    }

    function removeListingListener(vehicleListingId: number) {
        marketplaceDatabaseRefs.value[vehicleListingId].off('value');
        delete marketplaceDatabaseRefs.value[vehicleListingId];
        delete marketplaceSnapshots.value[vehicleListingId];
    }

    onUnmounted(() => {
        unsubscribeToMarketplaceRefs();
    });

    return {
        marketplaceDatabaseRefs,
        marketplaceSnapshots,
        removeListingListener,
    };
}

/**
 * Listen to a single marketplace listing
 *
 */
export function useSingleMarketplaceListingListener(vehicleListingId: number, onSnapshot: (payload: {
    isInitialSnapshot: boolean
    oldSnapshotValue: MarketplaceRefRecord | null
    newSnapshotValue: MarketplaceRefRecord | null
}) => void) {
    return useRdbListener<MarketplaceRefRecord>(`marketplace/${vehicleListingId}`, onSnapshot);
}

export function useAuctionTriggerWarningDialog() {
    function showAuctionTriggerWarningDialog(offerAmount: number, reservePrice: number, onConfirm: BDialogConfig['onConfirm'], onCancel: BDialogConfig['onCancel']) {
        openConfirmationDialog({
            title: 'Auction will be triggered',
            message: `Your offer of ${toCurrency(offerAmount)} meets or exceeds the floor price (${toCurrency(reservePrice)}), which will remove the listing from marketplace and trigger a one-day auction.`,
            confirmText: 'Continue',
            onConfirm,
            onCancel,
        });
    }

    return {
        showAuctionTriggerWarningDialog,
    }
}


export function useUpdateMarketplaceListingDetailsOnListenerUpdate({ marketplaceListingId, currentOffer, vehicleStatus }: {
    marketplaceListingId: number,
    currentOffer: marketplaceCurrentOfferDTO,
    vehicleStatus: VehicleStatus,
}) {
    const listingDetails = ref({
        currentOffer,
        numViews: 0,
        numOffers: 0,
        status: vehicleStatus,
    });
    const isCheckoutOrLater = ref(false);

    async function getUpdatedMarketplaceListingDetails() {
        try {
            const updatedListingDetails = await getMarketplaceListingDetails(marketplaceListingId);
            listingDetails.value = {
                ...listingDetails.value,
                ...updatedListingDetails,
            }
            return true;
        } catch {
            return false;
        }
    }

    async function getUpdatedMarketplaceListingDetailsOnSnapshot(
        { newSnapshotValue }: MarketplaceRefSnapshot, 
        { onStatusChange }: {
            onStatusChange?: (newStatus: VehicleStatus, oldStatus: VehicleStatus) => void,
        } = {}
    ) {
        if (newSnapshotValue.status) {
            if (onStatusChange) {
                onStatusChange(newSnapshotValue.status, listingDetails.value.status);
            }
            listingDetails.value.status = newSnapshotValue.status;
        }
        if (newSnapshotValue.views) {
            listingDetails.value.numViews = newSnapshotValue.views;
        }
        getUpdatedMarketplaceListingDetails();
        isCheckoutOrLater.value = newSnapshotValue?.status ? isVehicleStatusCheckoutOrLater(newSnapshotValue.status) : false;

        const currentOffer = listingDetails.value?.currentOffer;
        if (currentOffer?.marketplaceOfferId && newSnapshotValue?.offers?.[currentOffer.marketplaceOfferId]) {
            getUpdatedMarketplaceListingDetails();
        }
    }

    return {
        getUpdatedMarketplaceListingDetailsOnSnapshot,
        getUpdatedMarketplaceListingDetails,
        isCheckoutOrLater,
        listingDetails,
    }
}

export function useEditMarketplacePricing({ vehicleListingId, marketplaceListingId, listingType, yearMakeModel, context }: {
    vehicleListingId: number,
    marketplaceListingId?: number,
    yearMakeModel?: string,
    listingType: ListingType,
    context?: SetupContext<any[]>,
}) {
    const {
        getMarketplaceDetails,
        marketplaceDetailsRaw,
    } = useVehicleDetailsCards({ vehicleListingId });
    
    const loadingPricingDetails = ref(false);

    async function openEditMarketplacePricingModal({ fetchPricing, reservePrice, buyItNowPrice, canBuyItNow }: {
        reservePrice?: number,
        buyItNowPrice?: number,
        canBuyItNow?: boolean,
        fetchPricing?: boolean,
    }={}) {
        if (fetchPricing && marketplaceListingId) {
            loadingPricingDetails.value = true;
            await getMarketplaceDetails(marketplaceListingId);
            reservePrice = marketplaceDetailsRaw.value?.reservePrice;
            buyItNowPrice = marketplaceDetailsRaw.value?.buyItNowPrice;
            canBuyItNow = marketplaceDetailsRaw.value?.canBuyItNow;
            loadingPricingDetails.value = false;
        }
        openModal({
            component: TheEditListingPricingModal,
            props: {
                reservePrice,
                buyItNowPrice,
                canBuyItNow,
                listingType,
                showFields: ['reservePrice', 'buyItNowPrice']
            },
            events: {
                savePricing: ({ canBuyItNowObject: updatedPricing }: { canBuyItNowObject: { reservePrice?: number, buyItNowPrice?: number, canBuyItNow?: boolean } }) => {
                    saveMarketplacePricing(updatedPricing)
                },
            }
        });
    }

    const loadingSavePricing = ref(false);
    async function saveMarketplacePricing(pricing: {
        reservePrice?: number,
        buyItNowPrice?: number,
        canBuyItNow?: boolean,
    }) {
        loadingSavePricing.value = true;
        updateMarketplaceListing({
            vehicleListingId,
            ...pricing,
        }, {
            onSuccess: () => {
                loadingSavePricing.value = false;
                openToast('is-success', 'Listing pricing updated!');
                context?.emit('pricingUpdated', pricing);
            },
            onError: () => loadingSavePricing.value = false,
        });
    }

    return {
        openEditMarketplacePricingModal,
        loadingPricingDetails,
        loadingSavePricing,
    }
}

export function useEditSecondChancePricing({ vehicleListingId, yearMakeModel, context}: {
    vehicleListingId: number,
    yearMakeModel?: string,
    context?: SetupContext<any[]>
}) {
    const loadingPricingDetails = ref(false);
    async function openSecondChancePricingModal({ reservePrice, lowestNegotiatedPrice, fetchPricing, modalConfig={} }: {
        reservePrice?: number,
        lowestNegotiatedPrice?: number,
        fetchPricing?: boolean,
        modalConfig?: { 
            events?: { [key: string]: (...args: any[]) => any }
        }
    }) {
        if (fetchPricing) {
            loadingPricingDetails.value = true;
            await getSendToSecondChancePricing(vehicleListingId, {
                onSuccess: (fetchedPricing) => {
                    reservePrice = fetchedPricing.reservePrice;
                    lowestNegotiatedPrice = fetchedPricing.lowestNegotiatedAmount;
                    loadingPricingDetails.value = false;
                },
                onError: () => loadingPricingDetails.value = false,
            });
        }

        openModal({
            component: TheSendToSecondChanceModal,
            props: {
                vehicleListingId,
                vehicleName: yearMakeModel,
                reservePrice,
                lowestNegotiatedPrice,
            },
            ...modalConfig,
        });
    }

    const loadingSavePricing = ref(false);
    function openEditSecondChancePricingModal(pricing: {
        reservePrice?: number,
        lowestNegotiatedPrice?: number,
        fetchPricing?: boolean,
    }) {
        openSecondChancePricingModal({
            ...pricing,
            modalConfig: {
                events: {
                    confirm: ({ reservePrice }: { reservePrice: number }) => {
                        loadingSavePricing.value = true;
                        updateMarketplaceListing({
                            vehicleListingId,
                            reservePrice,
                        }, {
                            onSuccess: () => {
                                loadingSavePricing.value = false;
                                openToast('is-success', 'Listing pricing updated!');
                                context?.emit('pricingUpdated');
                            },
                            onError: () => loadingSavePricing.value = false,
                        })
                    }
                }
            }
        });
    }
    return {
        openSecondChancePricingModal,
        openEditSecondChancePricingModal,
        loadingPricingDetails,
        loadingSavePricing,
    }
}

export function useSendToSecondChance({ vehicleListingId, yearMakeModel, context }: {
    vehicleListingId: number,
    yearMakeModel?: string,
    context?: SetupContext<('sentToSecondChance' | any)[]>,
}) {
    const {
        openSecondChancePricingModal,
        loadingPricingDetails,
    } = useEditSecondChancePricing({
        vehicleListingId,
        yearMakeModel,
        context,
    });

    const loadingSendToSecondChance = ref(false);
    async function openSendToSecondChanceModal(pricing: {
        reservePrice?: number,
        lowestNegotiatedPrice?: number,
        fetchPricing?: boolean,
    }) {
        openSecondChancePricingModal({
            ...pricing,
            modalConfig: {
                events: {
                    confirm: ({ reservePrice }: { reservePrice: number }) => {
                        loadingSendToSecondChance.value = true;
                        sendToSecondChance(vehicleListingId, reservePrice, {
                            onSuccess: () => {
                                loadingSendToSecondChance.value = false;
                                openToast('is-success', 'Listing sent to Second Chance!');
                                context?.emit('sentToSecondChance');
                            }, 
                            onError: () => loadingSendToSecondChance.value = false,
                        });
                    }
                },
            }
        });
    }

    return {
        openSendToSecondChanceModal,
        loadingSendToSecondChance,
        loadingPricingDetails,
    }
}
